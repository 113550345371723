
import MFrame, { DelMessage, RouteListItem } from '@/components/frame/Frame.vue';
export default {
  components: {
    'm-frame': MFrame,
  },
  setup() {
    const menuList: RouteListItem[] = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'statistics',
        title: '数据概览',
      },
      {
        path: 'waybill',
        title: '运单号列表',
      },
      {
        path: 'carrier',
        title: '承运商查询',
      },
    ];

    const messageList: DelMessage[] = [
      { text: '删除本应用并不会影响购物商城的任何数据' },
      { text: '删除本应用仅会删除本应用上的功能以及数据' },
    ];

    return {
      menuList,
      messageList,
    };
  },
};
